import React from "react";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { isMobile } from "detect-touch-device";
import LocomotiveScroll from "locomotive-scroll";
import setBodyColor from "./SetBodyColor";
import setSocialDisplay from "./SetSocialDisplay";
import setSocialPosition from "./SetSocialPosition";

let aeromexico = "https://yeahpure.s3.us-east-2.amazonaws.com/aeromexico.png";
let atandt = "https://yeahpure.s3.us-east-2.amazonaws.com/att.png";
let cocacola = "https://yeahpure.s3.us-east-2.amazonaws.com/cocacola.png";
let comex = "https://yeahpure.s3.us-east-2.amazonaws.com/comex.png";
let google = "https://yeahpure.s3.us-east-2.amazonaws.com/google.png";
let spotify = "https://yeahpure.s3.us-east-2.amazonaws.com/spotify.png";
let uber = "https://yeahpure.s3.us-east-2.amazonaws.com/uber.png";
let shorts = "https://yeahpure.s3.us-east-2.amazonaws.com/shorts.png";
let hagen = "https://yeahpure.s3.us-east-2.amazonaws.com/haagen.png";
let pizzahut = "https://yeahpure.s3.us-east-2.amazonaws.com/pizzahut.png";
let victoria = "https://yeahpure.s3.us-east-2.amazonaws.com/victoria.png";
let lenovo = "https://yeahpure.s3.us-east-2.amazonaws.com/lenovo.png";
let motorola = "https://yeahpure.s3.us-east-2.amazonaws.com/motorola.png";
let nokia = "https://yeahpure.s3.us-east-2.amazonaws.com/nokia.png";
let volvo = "https://yeahpure.s3.us-east-2.amazonaws.com/volvo.png";
let ubereats = "https://yeahpure.s3.us-east-2.amazonaws.com/ubereats.png";
let miniso = "https://yeahpure.s3.us-east-2.amazonaws.com/miniso.png";
let pedigree = "https://yeahpure.s3.us-east-2.amazonaws.com/pedigree.png";
let timeout = "https://yeahpure.s3.us-east-2.amazonaws.com/timeout.png";
let pringles = "https://yeahpure.s3.us-east-2.amazonaws.com/pringles.png";
let trevor = "https://yeahpure.s3.us-east-2.amazonaws.com/trevor.png";
let duolingo = "https://yeahpure.s3.us-east-2.amazonaws.com/duolingo.png";
let chilango = "https://yeahpure.s3.us-east-2.amazonaws.com/chilango.png";
let cielito = "https://yeahpure.s3.us-east-2.amazonaws.com/cielito.png";
let chilinbalam = "https://yeahpure.s3.us-east-2.amazonaws.com/chilinbalam.png";

class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate() {};

  onResize = (event) => {};

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center title-one">
              <h1 className="show-mobile">La química tiene que ver con la confianza</h1>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={cielito}></img>
            </div>              
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={comex}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={chilango}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={chilinbalam}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={duolingo}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={trevor}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={aeromexico}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={atandt}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={cocacola}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={google}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={spotify}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={uber}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={hagen}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={lenovo}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={motorola}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={shorts}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={pringles}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={victoria}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={miniso}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={pedigree}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={pizzahut}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={timeout}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={ubereats}></img>
            </div>
            <div className="col-md-2 col-sm-4 col-4 partner-img">
              <img src={volvo}></img>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Partners {...props} navigation={navigation} />;
}
