import React from "react";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { isMobile } from "detect-touch-device";
import LocomotiveScroll from "locomotive-scroll";
import axios from "axios";
import setBodyColor from "./SetBodyColor";
import setSocialDisplay from "./SetSocialDisplay";
import setSocialPosition from "./SetSocialPosition";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classForm: "block",
      classSent: "none",
      briefClassName: "",
      nameClassName: "",
      emailClassName: "",
      briefText: "Asunto.",
      nameText: "Tu nombre y cargo.",
      emailText: "Tu correo.",
      sendBtnClassName: "send-btn-disabled" 
    };
    this.brief = React.createRef();
    this.name = React.createRef();
    this.contact = React.createRef();
  }

  componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate() {}

  addListeners = () => {
    window.addEventListener("resize", this.onResize);
    this.brief.current.addEventListener("input", this.updateValue);
    this.name.current.addEventListener("input", this.updateValue);
    this.contact.current.addEventListener("input", this.updateValue);
  }

  removeListeners = () => {
    window.removeEventListener("resize", this.onResize);
    this.brief.current.removeEventListener("input", this.updateValue);
    this.name.current.removeEventListener("input", this.updateValue);
    this.contact.current.removeEventListener("input", this.updateValue);
  }

  updateValue = (event) => {
    if (event.target.id == "brief-input") {
      this.setState({
        briefClassName: "",
        briefText: "Tu one line brief.",
      });
    } else if (event.target.id == "name-input") {
      this.setState({
        nameClassName: "",
        nameText: "Tu nombre y cargo.",
      });
    } else {
      this.setState({
        emailClassName: "",
        emailText: "Tu correo.",
      });
    }

    let value = this.contact.current.value.trim();
    this.contact.current.value = value;

    if (this.brief.current.value == "" || this.brief.current.value.length < 3) {
      this.setState({
        sendBtnClassName: "send-btn-disabled"
      });
      return;
    }

    if (this.name.current.value == "" || this.name.current.value.length < 3) {
      this.setState({
        sendBtnClassName: "send-btn-disabled"
      });
      return;
    }

    if (this.contact.current.value == "" || this.contact.current.value.length < 3) {
      this.setState({
        sendBtnClassName: "send-btn-disabled"
      });
      return;
    }

    if (this.validateEmail(this.contact.current.value) == null) {
      this.setState({
        sendBtnClassName: "send-btn-disabled"
      });
      return;
    }

    this.setState({
      sendBtnClassName: "send-btn"
    });
  }

  onResize = (event) => {
    
  };

  clickSendBtn = (event) => {
    event.preventDefault();

    if (this.brief.current.value == "" || this.brief.current.value.length < 3) {
      this.setState({
        briefClassName: "errorInput",
        briefText: "Faltó tu one line brief.",
      });
      this.brief.current.value = "";
      this.brief.current.focus();
      return;
    }

    if (this.name.current.value == "" || this.name.current.value.length < 3) {
      this.setState({
        nameClassName: "errorInput",
        nameText: "Faltó tu nombre y cargo.",
      });
      this.name.current.value = "";
      this.name.current.focus();
      return;
    }

    if (this.contact.current.value == "" || this.contact.current.value.length < 3) {
      this.setState({
        emailClassName: "errorInput",
        emailText: "Faltó tu correo.",
      });
      this.contact.current.value = "";
      this.contact.current.focus();
      return;
    }

    if (this.validateEmail(this.contact.current.value) == null) {
      console.log(this.validateEmail(this.contact.current.value));
      return;
    }
    
    let userData = {
      brief: this.brief.current.value,
      name: this.name.current.value,
      contact: this.contact.current.value
    };
    axios.post("/form.php", userData).then((response) => {
      console.log(response);
      this.setState({
        classForm: "none",
        classSent: "block"
      });
      this.brief.current.value = "";
      this.name.current.value = "";
      this.contact.current.value = "";
    })
    .catch((error) => {
      console.log(error);
    });
  }

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center" style={{ display: this.state.classForm }}>
              <p>
                ¿Cuéntanos como te podemos ayudar?
              </p>
              <input type="text" placeholder={this.state.briefText} ref={this.brief} id="brief-input" className={this.state.briefClassName} autoComplete="off"></input>
              <p>
                Nombre
              </p>
              <input type="text" placeholder={this.state.nameText} ref={this.name} id="name-input" className={this.state.nameClassName} autoComplete="off"></input>
              <p>
                Tu correo
              </p>
              <input type="text" placeholder={this.state.emailText} ref={this.contact} id="email-input" className={this.state.emailClassName} autoComplete="off"></input>
            </div>
            <div className="col-12 text-center" style={{ display: this.state.classForm }}>
                <a href="#" onClick={this.clickSendBtn} className={this.state.sendBtnClassName}>Enviar</a>
            </div>
            <div style={{ display: this.state.classSent }} className="col-12 text-center"><h1 className="thank-you">Recibido. Volvemos contigo <span className="card-font-italic">muy pronto.</span></h1></div>
          </div>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Contact {...props} navigation={navigation} />;
}
