import React from "react";
import gsap from "gsap";
import close from "../images/closeBlanco.svg";

class ModalVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVideoIsOpened: false,
      currentVideoURL: "",
    };
    this.modal_video = React.createRef();
    this.modalVideo = null;
    this.modalVideoTweenIn = null;
    this.modal_video = React.createRef();
    this.modal_video_tween = null;
    this.modal_video = React.createRef();
    this.modalVideoTween = null;
  }

  componentDidMount() {}

  componentDidUpdate() {}

  openModalVideoBox = (url) => {
    this.setState({
      currentVideoURL: url,
    });
    this.setState({
      modalVideoIsOpened: true,
    });
    this.modalVideoTweenIn = gsap.to(this.modalVideo, {
      duration: 0.6,
      delay: 0,
      opacity: 1,
      width: "100%",
      height: "100%",
      top: "0px",
      left: "0px",
      ease: "power4.inOut",
      onComplete: () => {
        this.modal_video.current.currentTime = 0;
        this.modal_video.current.play();
      },
    });
    this.modal_video.current.load();
  };

  closeModalVideo = (event) => {
    event.preventDefault();
    this.modal_video.current.pause();
    this.setState({
      modalVideoIsOpened: false,
    });
    if (this.props["close-completion"] != null) {
      this.props["close-completion"]();
    }
  };

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  render() {
    return (
      <div
        id="modal-video"
        ref={(div) => (this.modalVideo = div)}
        style={
          this.state.modalVideoIsOpened
            ? {
                display: "block",
                opacity: "0",
                background: "#000",
                top: "-25%",
                left: "-25%",
                width: "150%",
                height: "150%",
              }
            : {
                display: "none",
              }
        }
      >
        <video
          ref={this.modal_video}
          playsInline
          webkit-playsinline="true"
          controls
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
        >
          <source src={this.state.currentVideoURL} type="video/mp4"></source>
        </video>
        <a href="#" onClick={this.closeModalVideo} className="closeVideoModal">
          <img width={40} height={40} src={close}></img>
        </a>
      </div>
    );
  }
}

export default ModalVideo;
