import React from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { useNavigate } from "react-router-dom";
import { isMobile } from "detect-touch-device";
import LocomotiveScroll from "locomotive-scroll";
import { useGSAP } from '@gsap/react';
import { render } from "react-dom";
import { Stage, Container, Sprite } from "@pixi/react";
import { Assets } from 'pixi.js';
import setBodyColor from "./SetBodyColor";
import setSocialDisplay from "./SetSocialDisplay";
import setSocialPosition from "./SetSocialPosition";
import Partners from "./Partners";
import ModalVideo from "./ModalVideo.js";
import Contact from "./Contact.js";

import logo from "../images/logo-dg.svg";
import logow from "../images/logo-dg-white.svg";
import playBtn from "../images/play-btn.png";
import uno from "../images/work/uno.png";
import dos from "../images/work/dos.png";
import tres from "../images/work/tres.png";
import cuatro from "../images/work/cuatro.png";
import cinco from "../images/work/cinco.png";
import seis from "../images/work/seis.png";
import siete from "../images/work/siete.png";
import ocho from "../images/work/ocho.png";
import nueve from "../images/work/nueve.png";
import quote  from "../images/quote.svg";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      raycastCursorPointer: "default",
    };
    this.modalVideo = React.createRef();
    this.finishSplash = this.finishSplash.bind(this);
    this.manager = new THREE.LoadingManager();
    this.raycaster = new THREE.Raycaster();
    this.FLOOR_RES = 60;
    this.FLOOR_WIDTH = 5600;
    this.FLOOR_DEPTH = 4800;
    this.MOVE_SPD = 1.9;
    this.mouseX = 0;
    this.mouseY = 0;
    this.onResize();
    this.initZVideoPlane = 2750 * 0.5;
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    this.registerParallaxElements();
    this.props.finishSplash(this.finishSplash);
    this.addListeners();

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    
    // Scene
    this.scene = new THREE.Scene();
    // this.scene.fog = new THREE.FogExp2(0x1c3c4a, 0.00045);
    
    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: false,
      preserveDrawingBuffer: true,
    });
    // this.renderer.setClearColor(0xffffff);
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    this.mount.appendChild(this.renderer.domElement);

    // Camera
    this.camera = new THREE.PerspectiveCamera(70, width / height, 1, 10000);
    this.camera.position.z = 2750;
        
    // Lights
    // var hemisphereLight = new THREE.HemisphereLight(0xe3feff, 0xe6ddc8, 0.7);
    // hemisphereLight.position.y = 300;
    // this.scene.add(hemisphereLight);

    // var centerLight = new THREE.SpotLight(0xb7f9ff, 1);
    // centerLight.position.set(2500, 300, 2000);
    // centerLight.penumbra = 1;
    // centerLight.decay = 5;
    // this.scene.add(centerLight);

    // this.pointLight = new THREE.PointLight(0xe07bff, 1.5);
    // this.pointLight.position.z = 200;
    // this.scene.add(this.pointLight);

    // this.pointLight2 = new THREE.PointLight(0xff4e00, 1.2);
    // this.pointLight2.position.z = 200;
    // this.scene.add(this.pointLight2);

    const light = new THREE.AmbientLight(0xffffff);
    this.scene.add(light);

    // Plane
    /*var path = "images/";
    var format = '.jpg';
    var urls = [
      path + 'px' + format, path + 'nx' + format,
      path + 'py' + format, path + 'ny' + format,
      path + 'pz' + format, path + 'nz' + format
    ];

    var cubeMaterial = new THREE.MeshPhongMaterial({
      color: 0x447080,
      combine: THREE.MixOperation,
      side: THREE.DoubleSide,
      reflectivity: 0.5,
      // shading: THREE.FlatShading
    });

    this.floorGroup = new THREE.Object3D();

    var floorMaterial = new THREE.MeshPhongMaterial({
      color: 0xeeeeee,							
      side: THREE.DoubleSide,
      blending: THREE.AdditiveBlending,
      wireframe: true
    });

    this.floorGeometry = new THREE.PlaneGeometry(this.FLOOR_WIDTH + 1200, this.FLOOR_DEPTH, this.FLOOR_RES, this.FLOOR_RES);
    var floorMesh = new THREE.Mesh(this.floorGeometry, cubeMaterial);
    var floorMesh2 = new THREE.Mesh(this.floorGeometry, floorMaterial);

    floorMesh2.position.y = 20;
    floorMesh2.position.z = 5;
    this.floorGroup.add(floorMesh);
    this.floorGroup.add(floorMesh2);
    this.scene.add(this.floorGroup);
    floorMesh.rotation.x = Math.PI / 1.65;
    floorMesh2.rotation.x = Math.PI / 1.65;
    this.floorGroup.position.y = 180;*/

    // Create Video Plane
    this.createVideoPlane();

    // GLTF
    const loader = new GLTFLoader();
    loader.load('./gltf/dos/Planta_domi_S_anim.gltf', this.glftLoad); 

    // Create Plane Last Logo
    this.createPlaneLogo();

    // Start Three
    this.start();
  }

  glftLoad = async (gltf) => {  
    this.model = gltf.scene;
    this.model.scale.set(70000, 70000, 70000);
    this.model.position.set(3000, -1700, -900);
    this.scene.add(this.model);
    var floorMaterial = new THREE.MeshBasicMaterial({
      color: 0xa1a1a1,							
      wireframe: true
    });
    this.model.children[0].material = floorMaterial;
    //this.model.children[0].material.wireframe = true;
    await this.renderer.compileAsync(this.model, this.camera, this.scene);
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  rotateInDegrees = (object, xDeg, yDeg, zDeg) => {
    object.rotation.x = THREE.MathUtils.degToRad(xDeg);
    object.rotation.y = THREE.MathUtils.degToRad(yDeg);
    object.rotation.z = THREE.MathUtils.degToRad(zDeg);
  };

  renderScene = () => {
    var timer = -0.0002 * Date.now();
    if (this.renderer) {  
      // this.pointLight.position.x = 2400 * Math.cos(timer);
      // this.pointLight.position.z = 2400 * Math.sin(timer);

      // this.pointLight2.position.x = 1800 * Math.cos(-timer * 1.5);
      // this.pointLight2.position.z = 1800 * Math.sin(-timer * 1.5);

      this.camera.position.x += (this.mouseX - this.camera.position.x) * .05;
      this.camera.position.y += (-this.mouseY - this.camera.position.y) * .05;
      this.camera.lookAt(this.scene.position);

      this.renderer.render(this.scene, this.camera);
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate() {}

  addListeners = () => {
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    window.addEventListener("resize", this.onResize);
    window.addEventListener('mousemove', this.onDocumentMouseMove, false);
    window.addEventListener("scroll", this.onScroll);
  }

  removeListeners = () => {
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("resize", this.onResize);  
    window.removeEventListener('mousemove', this.onDocumentMouseMove);
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = (event) => {
    var scrollY = window.scrollY;
    //this.floorGroup.position.y = (scrollY * 0.07) + 180;
    this.moveModelGLTF();
    this.moveVideoPlane();
    this.movePlaneLogo();
  }

  onDocumentMouseMove = (event) => {
    //this.mouseX = (event.clientX - (this.windowHalfX)) * 0.3;
    //this.mouseY = (event.clientY - (this.windowHalfY)) * 0.3;
  }

  finishSplash = () => {
    window.scrollTo(0, 0);
    this.props["hide-loader"](0.5, 1);  
  }

  onFocus = () => {
    
  };

  onBlur = () => {
    
  };

  moveModelGLTF = () => {
    if (this.camera, this.model) {
      this.model.position.y = ((window.scrollY * 0.65) - 1700);
    }
  };

  createVideoPlane = () => { 
    const video = document.createElement("video");
    video.src = "videos/passionate.mp4";
    video.loop = true;
    video.muted = true;
    video.play();

    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;
    videoTexture.generateMipmaps = false;

    const h = 2 * Math.tan((this.camera.fov * Math.PI) / 360) * this.initZVideoPlane;
    const w = h * (960 / 540);

    const geometry = new THREE.PlaneGeometry(w, h, 10, 6);
    const material = new THREE.MeshBasicMaterial({ map: videoTexture });
    this.plane = new THREE.Mesh(geometry, material);
    this.plane.position.z = this.initZVideoPlane;
    this.plane.material.transparent = true;
    this.scene.add(this.plane);
  };

  moveVideoPlane = () => { 
    if (this.camera, this.plane) {
      // PosZ
      var offset = 0;
      const zInicial = this.initZVideoPlane;
      const zFinal = -this.initZVideoPlane;
      const scrollMax = (document.body.scrollHeight - window.innerHeight) / 5;
      const scrollMax2 = (document.body.scrollHeight - window.innerHeight) / 12;
      var scrollRatio = (window.scrollY < offset) ? 0 : (window.scrollY - offset) / scrollMax;
      var scrollRatio2 = (window.scrollY < offset) ? 0 : (window.scrollY - offset) / scrollMax2;
      if (scrollRatio <= 1) {
        this.plane.position.z = zInicial + scrollRatio * (zFinal - zInicial);
      } else {
        this.plane.position.z = zFinal;
      }
      this.plane.material.opacity = 1 - scrollRatio2;

      // RotZ
      // const rotXInit = 0;
      // const rotXFinal = 90;
      // var offset = ((document.body.scrollHeight - window.innerHeight) / 10) * 0.5;
      // scrollRatio = (window.scrollY < offset) ? 0 : (window.scrollY - offset) / scrollMax;
      // if (scrollRatio <= 1) {
      //   this.rotateInDegrees(this.plane, rotXInit + scrollRatio * (rotXFinal - rotXInit), 0, 0);
      // } else {
      //   this.rotateInDegrees(this.plane, rotXFinal, 0, 0);
      // }

      // PosY
      // const yInit = 0;
      // const yFinal = 3200;
      // offset = (document.body.scrollHeight - window.innerHeight) / 5;
      // scrollRatio = (window.scrollY < offset) ? 0 : (window.scrollY - offset) / scrollMax;
      // if (scrollRatio <= 2) {
      //   this.plane.position.y = yInit + scrollRatio * (yFinal - yInit);
      // } else {
      //   this.plane.position.y = yFinal * 2;
      // }
    }
  };

  createPlaneLogo = () => {
    const h = 400;
    const w = 400;
    const geometry = new THREE.PlaneGeometry(w, h, 4, 4);
    const loaderTexture = new THREE.TextureLoader();
    const material = new THREE.MeshBasicMaterial({ map: loaderTexture.load("images/logo-final.png"), transparent: true, opacity: 0 });
    this.planeLogo = new THREE.Mesh(geometry, material);
    this.planeLogo.position.z = -(this.initZVideoPlane * 2);
    this.scene.add(this.planeLogo);
  };

  movePlaneLogo = () => { 
    if (this.camera, this.planeLogo) {
      // PosZ
      var offset = ((document.body.scrollHeight - window.innerHeight) / 10) * 9;
      const zInicial = -(this.initZVideoPlane * 2);
      const zFinal = (this.initZVideoPlane * 1);
      const scrollMax = (document.body.scrollHeight - window.innerHeight) - offset;
      var scrollRatio = (window.scrollY < offset) ? 0 : (window.scrollY - offset) / scrollMax;
      if (scrollRatio <= 1) {
        this.planeLogo.position.z = zInicial + scrollRatio * (zFinal - zInicial);
        this.planeLogo.material.opacity = scrollRatio;
      } else {
        this.planeLogo.position.z = zFinal;
        this.planeLogo.material.opacity = 1;
      }
      console.log(scrollRatio);
    }
  };

  onResize = (event) => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.windowHalfX = this.w / 2;
    this.windowHalfY = this.h / 2;
    if (this.renderer) {
      this.camera.aspect = this.w / this.h;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.w, this.h);
    }
  };

  openModalVideo = (event) => {
    event.preventDefault();
    this.modalVideo.current.openModalVideoBox(
      "../videos/demoreel.mp4"
    );
  };

  onCloseModalVideoCompletion = () => {

  };

  registerParallaxElements = () => {
    gsap.from(".about-btn", {
      scrollTrigger: {
        trigger: ".about-btn",
        //toggleActions: "restart pause none pause",
        start: "0px 80%",
        end: "0px 50%",
        //end: "+=300",
        scrub: true,
      },
      scale: 0.85,
      opacity: 0,
      y: 50,
      ease: "none",
      duration: 0.5
    });

    // gsap.from(".div1 img", {
    //   scrollTrigger: {
    //     trigger: ".div1",
    //     //toggleActions: "restart pause none pause",
    //     start: "0px 80%",
    //     end: "0px 50%",
    //     //end: "+=300",
    //     scrub: true,
    //     markers: true,
    //   },
    //   scale: 0.7,
    //   opacity: 0,
    //   y: 100,
    //   ease: "none",
    //   duration: 0.5
    // });

    // gsap.from(".div2 img", {
    //   scrollTrigger: {
    //     trigger: ".div2",
    //     toggleActions: "restart pause none pause",
    //     start: "0px 100%",
    //     end: "-=300",
    //     //start: "0px 100%",
    //     //end: "0px 50%",
    //     //scrub: true,
    //     //markers: true,
    //   },
    //   y: 200,
    //   ease: "none",
    //   duration: 2
    // });

    // ScrollTrigger.create({
    //   trigger: ".container-section-work",
    //   start: "top top",
    //   end: "+=100%",
    //   pin: true,
    //   markers: true
    // });
  };

  render() {
    const { navigation } = this.props;
    return (
      <>
        {/* Canvas */}
        <div className="container-canvas"
          style={{ cursor: this.state.raycastCursorPointer, backgroundColor: "#ffffff" }}
          ref={(mount) => {
            this.mount = mount;
          }}
        >
        </div>
        {/* Home */}
        <section className="container-section-home">
          <div className="left-column bg-green-color">
            <img className="left-column-logo" src={logow}></img>
          </div>
          <div className="content-home">
            <div className="content-home-first">
              <h2>digital.<span className="green-color">garden</span></h2>
            </div>
            <div className="content-home-second">
              <div className="first">
                <div className="one">
                  <img width={40} height="auto" src={logo}></img>
                </div>
                <div className="two">
                  <p>Ayudamos a las marcas a crear experiencias únicas e inolvidables, a partir de nuestra investigación y nuevas herramientas tecnológicas.</p>
                </div>
              </div>
              <div className="second">
                <h1>Somos un laboratorio tecnológico</h1>
              </div>
            </div>
          </div>
        </section>
        {/* About */}
        <section className="container-section-about">
          <div className="left-column"></div>
          <div className="content-about">
            <div className="content-about-first">
              <h1>  
                <span>01.</span>
                <span>Acerca</span>
                <span>Ayudamos a las marcas a crear experiencias únicas e inolvidables, a partir de nuestra investigación y nuevas herramientas tecnológicas.</span>
              </h1>
            </div>
            <div className="content-about-second">
              <a href="#" className="about-btn" onClick={this.openModalVideo}>
                <img src={playBtn}></img>
              </a>
            </div>
          </div>
        </section>
        {/* Work */}
        <section className="container-section-work">
          <div className="left-column"></div>
          <div className="content-work">
            <div className="content-work-first">
              <h1>
                <span>02.</span>
                <span>Nuestro trabajo</span>
                <span>Lorem ipsum.</span>
              </h1>
            </div>
            <div className="content-work-second">
              <div className="div1">
                <img src={uno}></img>
              </div>
              <div className="div2">
                <img src={dos}></img>
              </div>
              <div className="div3">
                <img src={tres}></img>
              </div>
              <div className="div4">
                <img src={cuatro}></img>
              </div>
              <div className="div5">
                <img src={cinco}></img>
              </div>
              <div className="div6">
                <img src={seis}></img>
              </div>
              <div className="div7">
                <img src={siete}></img>
              </div>
              <div className="div8">
                <img src={ocho}></img>
              </div>
              <div className="div9">
                <img src={nueve}></img>
              </div>
              <div className="div10">
              
              </div>
            </div>
          </div>
        </section>
        {/* Quote */}
        <div className="container-section-quote1">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>
                  Velocidad e<br></br>
                  <span className="white-color">innovación</span><br></br>
                  son las leyes que<br></br>
                  nos rigen.
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right quote-icon">
                <img src={quote}></img>
              </div>
            </div>
          </div>
        </div>
        {/* Partners */}
        <section className="container-section-partners">
          <Partners></Partners>
        </section>
        {/* Contact */}
        <section className="container-section-contact">
          <Contact></Contact>
        </section>
        {/* Otra */}
        <section className="container-section-about">
          
        </section>
        <ModalVideo
          ref={this.modalVideo}
          close-completion={this.onCloseModalVideoCompletion}
        ></ModalVideo>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Home {...props} navigation={navigation} />;
}